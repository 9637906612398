import { Pipe, PipeTransform } from '@angular/core';
import { DTO } from 'src/dto/dto';

const EDT_NUM_LENGTH = 6;

export const EdtCode = (asset: DTO) => {
    if (asset?.id == undefined)
        return "";
    let typeCode = asset.edtCode || asset['edtcode'];
    let displayResult: string;

    if (typeCode == undefined)
        typeCode = 'EDTID';

    // Update BCH-PM: We never keep switching out the EDT# with external numbers
    // EDT means it was Generated from Elevate Dynatrace, therefore WE ALWAYS DISPLAY
    // Also the EDT will be included within the dto.edtCode value.
    // We have a regular Elevate ID -- render it all nice.
    if (typeof asset.idx == "string" && asset.idx.length > 0 && parseInt(asset.idx) != asset.id)
        displayResult = `${typeCode}-${asset.id.toString().padStart(EDT_NUM_LENGTH, '0')} [${asset.idx}]`;
    else
        displayResult = `${typeCode}-${asset.id.toString().padStart(EDT_NUM_LENGTH, '0')}`;

    // Update BCH-PM: Now if we find they have an alternate, we show it to the side of the EDT.
    // Both numbers can be referenced at any time, EDT numbers however, are guaranteed
    // Check if we have a foreign key from an external system
    // if (asset.idx && parseInt(asset.idx) != asset.id)
    //     displayResult = displayResult + `(IDX: ${asset.idx})`;

    return displayResult;
}

@Pipe({
    name: 'edtcode',
    standalone: true
})
export class EDTCodePipe implements PipeTransform {

    public transform(item: DTO): string {
        return EdtCode(item);
    }
}
